import { datadogRum } from '@datadog/browser-rum';
declare const IS_LOCAL: boolean;

declare global {
  interface Window {
    userGuiding?: any;
  }
}

Promise.all([System.import('single-spa'), System.import('single-spa-layout')]).then(([singleSpa, singleSpaLayout]) => {
  const { constructApplications, constructLayoutEngine, constructRoutes } = singleSpaLayout;
  const { registerApplication, start } = singleSpa;

  const routes = constructRoutes(document.querySelector('#single-spa-layout'));
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });
  applications.forEach(registerApplication);


  const isLocal: boolean = IS_LOCAL;
  if (!isLocal) {
    datadogRum.init({
      applicationId: 'efe6b993-0f8f-4b3c-8d81-1547c6848164',
      clientToken: 'pub3da8951125ae791bc0151f447f831098',
      site: 'datadoghq.com',
      service:'portalcliente',
      env:'production',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      enableExperimentalFeatures: ['clickmap'],
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel:'mask-user-input'
    });
  
    datadogRum.startSessionReplayRecording();
  
    let data = localStorage.getItem("USER");
    let result = data ? JSON.parse(data) : [];
  
    if(result && result.length > 0) {
      datadogRum.setUser({
        id: result[0]?.id,
        name: result[0]?.name,
        email: result[0]?.email
      });
    }
    
    window.userGuiding.identify(result[0]?.id, {
      email: result[0]?.email,
      name: result[0]?.name,
      created_at: Date.now(),
    })
  }

  start();
});